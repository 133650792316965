<template>
	<CRow>
		<CCol col="12">
			<CCard>
				<CCardHeader>
					Promemoria
				</CCardHeader>
				<CCardBody>
					<CForm>
						<CRow>
							<CCol
								xs="12"
								sm="6"
								md="6"
								lg="6"
								xl="6"
								xxl="6"
								class="mb-sm-2 mb-0"
							>
								<CRow>
									<CCol
										xs="12"
										sm="12"
										md="12"
										lg="12"
										xl="12"
										xxl="12"
										class="mb-sm-2 mb-0"
									>
										<CInput
											label="Titolo"
											:value="validatorObj.title.$model"
											@update:value="setTitle"
											:isValid="requiredValidTouched(validatorObj.title)"
										>
										</CInput>
									</CCol>
									<CCol
										xs="12"
										sm="12"
										md="12"
										lg="12"
										xl="12"
										xxl="12"
										class="mb-sm-2 mb-0"
									>
										<CTextarea
											label="Messaggio"
											:value="validatorObj.body.$model"
											@update:value="setBody"
											:isValid="requiredValidTouched(validatorObj.body)"
										>
										</CTextarea>
									</CCol>
								</CRow>
							</CCol>
							<CCol
								xs="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								xxl="12"
								class="mb-sm-2 mb-0"
							>
								<template v-if="reminders && reminders.length > 0">
									<promemoria-singolo
										v-for="(reminder, index) in reminders"
										:key="reminder.id"
										@addReminder="pushReminder"
										@deleteReminder="removeReminder(index)"
										:params="{model: reminder, daysOfWeekOptions: daysOfWeekOptions, minutesOptions: minutesOptions, hoursOptions: hoursOptions, isLast: index === reminders.length - 1}"
									>
									</promemoria-singolo>
								</template>
								<!-- <p
									v-for="error of validatorObj.$errors"
									:key="error.$uid"
								>
									{{ error.$message }}
								</p> -->
							</CCol>
						</CRow>
					</CForm>
				</CCardBody>
				<CCardFooter>
					<div class="footer-actions">
						<CButton
							color="sdAzure"
							@click="updatePromemoria"
							:disabled="!(validatorObj.$errors && validatorObj.$errors.length === 0)"
						>Aggiorna</CButton>
					</div>
				</CCardFooter>
			</CCard>
		</CCol>
	</CRow>
</template>

<script>

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, validSameAsTouched, isValidUUID, deleteModalOptions, pushToast, promemoriaOptionsGenerator, newGuid } from "../../utilities/utilities";
import { mapActions } from "vuex";
import Vue from 'vue';

export default {
	name: 'Promemoria',
	created() {
		this.daysOfWeekOptions = [
			{ value: 0, label: 'Lunedì' },
			{ value: 1, label: 'Martedì' },
			{ value: 2, label: 'Mercoledì' },
			{ value: 3, label: 'Giovedì' },
			{ value: 4, label: 'Venerdì' },
			{ value: 5, label: 'Sabato' },
			{ value: 6, label: 'Domenica' },
		];
		this.minutesOptions = this.promemoriaOptionsGenerator(60);
		this.hoursOptions = this.promemoriaOptionsGenerator(24);
		this.isLoading = true;
		this.$store.dispatch("getReminders").then(x => {
			const reminders = x.reminders;
			this.title = x.title;
			this.body = x.body;
			if (reminders && reminders.length > 0) {
				this.reminders = reminders.map(x => {
					x['id'] = newGuid();
					return x;
				});
			} else {
				this.reminders.push({ dayOfWeek: undefined, hour: undefined, minute: undefined, id: newGuid() });
			}
			this.isLoading = false;
		});
	},
	validations() {
		return {
			title: { required },
			body: { required },
			daysOfWeeksSelected: { required },
			hoursSelected: { required },
			minutesSelected: { required },
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			daysOfWeekOptions: [
				{ value: 0, label: 'Lunedì' },
				{ value: 1, label: 'Martedì' },
				{ value: 2, label: 'Mercoledì' },
				{ value: 3, label: 'Giovedì' },
				{ value: 4, label: 'Venerdì' },
				{ value: 5, label: 'Sabato' },
				{ value: 6, label: 'Domenica' },
			],
			minutesOptions: [],
			hoursOptions: [],
			hoursSelected: undefined,
			minutesSelected: undefined,
			daysOfWeeksSelected: undefined,
			body: "",
			title: "",
			reminders: [],
		}
	},
	methods: {
		...mapActions(["getReminders"]),
		setBody(value) {
			this.validatorObj.body.$model = value;
		},
		setTitle(value) {
			this.validatorObj.title.$model = value;
		},
		pushReminder() {
			this.reminders.push({ dayOfWeek: undefined, hour: undefined, minute: undefined, id: newGuid() });
		},
		removeReminder(index) {
			if (index === 0 && this.reminders.length - 1 === 0) {
				Vue.set(this.reminders, 0, { dayOfWeek: undefined, hour: undefined, minute: undefined, id: newGuid() })
			} else {
				this.reminders.splice(index, 1);
			}
		},
		updatePromemoria() {
			this.$store.commit('loadState', true);
			this.$store.dispatch('updateReminders', {
				title: this.title,
				body: this.validatorObj.body.$model,
				reminders: this.reminders.map(rem => {
					delete rem['id'];
					return rem;
				}),
			}).then(x => {
				if (x) {
					this.deleteModalOptions();
					this.pushToast({ title: 'Completato', body: 'Promemoria salvati', color: 'success' });
				}
				this.$store.commit('loadState', false);
			});
		},
		requiredValidTouched,
		validSameAsTouched,
		isValidUUID,
		deleteModalOptions,
		pushToast,
		promemoriaOptionsGenerator,
		newGuid
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>